<template>
<div class="doc-container">
	<CRow>
        <CCol col="8">
			<CCard class="p-0">
				<CCardHeader class="d-flex">
					<div class="flex-grow-0">
						<div class="media-img-wrap">
							<div v-if="document.user.profile_picture_thumb_base64" class="avatar avatar-sm content-center">
								<img :src="document.user.photo" alt="user" class="avatar-img rounded-circle img-fluid">
							</div>
							<div v-else class="avatar avatar-sm">
								<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
							</div>                                                
						</div>
					</div>
					<div class="flex-grow-1 ml-10">
						<div class="d-flex flex-column">
							<div><b> {{ document.user.rank }} {{ document.user.fullname }} </b></div>
							<div class="font-italic small">{{ document.user.position }}</div>
						</div>
					</div>
					<div class="flex-grow-0 ml-10 text-right">
						<font-awesome-icon icon="ellipsis-v" class="clickable ml-10"/>
					</div>
				</CCardHeader>
				<CCardBody class="position-relative">
					<div class="doc-preview-container">
						<div v-if="document.loader == true" class="text-center p-3 mt-20">
							<font-awesome-icon size="2x" icon="spinner" spin class="mr-10 mb-20"/><br/>
							Memuat surat...
						</div>
						<div v-else>
							<div v-if="document.base64 == ''" class="text-center p-3">
								<font-awesome-icon icon="info-circle" size="2x" class="text-info mb-10"/><br/>
								Pratinjau Tidak Tersedia
							</div>
							<img v-else :src="document.base64" alt="Pratinjau Surat" class="img-fluid">
						</div>
					</div>
					<div class="doc-info-container">
						<div class="doc-info-title">
							{{ document.data.title }}
						</div>
						<div class="doc-info-number">
							Nomor Surat : {{ document.data.doc_no }}
						</div>
						<div class="doc-info-action">
							<CButton class="btn-sm" @click="showApprovalModal">
								<font-awesome-icon icon="cogs" class="mr-1"/> Revisi
							</CButton>
						</div>
					</div>
				</CCardBody>
			</CCard>
		</CCol>
        <CCol col="4">
			<CCard class="p-0">
				<CCardHeader>
					<h5 class="mb-0" style="font-size: 110%"><font-awesome-icon icon="copy" class="mr-10"/> Aktivitas</h5>
				</CCardHeader>
				<CCardBody class="doc-activity-container">
					<CTabs :active-tab.sync="activeTab">
						<CTab>
							<template slot="title">
								<CBadge color="warning" shape="pill" class="text-white mr-1">{{ comments.data.length }}</CBadge>
								<font-awesome-icon icon="comment-alt"/> Percakapan
							</template>
							<div v-if="comments.data.length == 0" class="text-center p-3">
								<font-awesome-icon icon="info-circle" size="2x" class="text-info mb-10"/><br/>
								Tidak Ada Percakapan
							</div>
							<div v-else class="mt-1 mb-20">
								<CListGroup class="mb-10">
									<CListGroupItem href="#" v-for="(item, index) in comments.data" :key="index" class="flex-column align-items-start">
										<div class="d-flex w-100">
											<div class="flex-grow-0 mr-10">
												<div class="media-img-wrap">
													<div v-if="item.user_photo && item.user_photo != ''" class="avatar avatar-sm content-center">
														<img :src="item.user_photo" alt="user" class="avatar-img rounded-circle img-fluid">
													</div>
													<div v-else class="avatar avatar-sm">
														<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
													</div>                                                
												</div>
											</div>
											<div class="flex-grow-1">
												<div class="d-flex flex-column small">
													<div class="flex-grow-1">
														<b>
															{{item.user_fullname}}
														</b> 
														<vue-time-ago class="vue-fontawesome time-ago-notif" refresh :datetime="(item.created_at)" locale="id" :long="true"></vue-time-ago>
													</div>
													<span class="flex-grow-1 text-ellipsis">{{item.message}}</span>
												</div>
											</div>
										</div>
									</CListGroupItem>
								</CListGroup>
								<CButton v-if="comments.showMore" @click="showMoreComments" class="btn-link btn-sm w-100">
									<font-awesome-icon v-if="comments.loader" icon="spinner" spin class="mr-10"/>
									<font-awesome-icon v-else icon="chevron-down" class="mr-10"/>
									Lihat lebih banyak
								</CButton>
								<CButton v-if="!comments.showMore && comments.data.length > 5" @click="resetComments" class="btn-link btn-sm w-100">
									<font-awesome-icon icon="chevron-up" class="mr-10"/>
									Muat lebih sedikit
								</CButton>
							</div>
							<div class="doc-comment-input-container">
								<div class="d-flex w-100">
									<div class="flex-grow-0 content-center mr-10">
										<div class="media-img-wrap">
											<div v-if="profile_picture" class="avatar avatar-sm content-center">
												<img :src="profile_picture" alt="user" class="avatar-img rounded-circle img-fluid">
											</div>
											<div v-else class="avatar avatar-sm">
												<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
											</div>                                                
										</div>
									</div>
									<div class="flex-grow-1">
										<CInput 
											type="hidden"
											v-model="comments.form.input.doc_id"/>
										<CInput 
											class="comment-bar"
											v-model="comments.form.input.message"
											placeholder="Beri komentar...">
											<template #append-content>
												<div class="clickable" @click="postComment">
													<font-awesome-icon v-if="comments.loader" icon="spinner" spin/>
													<font-awesome-icon v-else icon="paper-plane"/>
												</div>
											</template>
										</CInput>
									</div>
								</div>
							</div>
						</CTab>
						<CTab>
							<template slot="title">
								<font-awesome-icon icon="history"/> Riwayat
							</template>
							<div class="mt-1 mb-20">
								<CListGroup>
									<CListGroupItem href="#" v-for="(item, index) in activity.data" :key="index" class="flex-column align-items-start">
										<div class="d-flex w-100">
											<div class="flex-grow-0 mr-10">
												<div class="media-img-wrap">
													<div v-if="item.user_photo && item.user_photo != ''" class="avatar avatar-sm content-center">
														<img :src="item.user_photo" alt="user" class="avatar-img rounded-circle img-fluid">
													</div>
													<div v-else class="avatar avatar-sm">
														<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
													</div>                                                
												</div>
											</div>
											<div class="flex-grow-1">
												<div class="small">
													<p class="mb-0">
														<vue-time-ago class="vue-fontawesome time-ago-notif" refresh :datetime="(item.created_at)" locale="id" :long="true"></vue-time-ago> | 
														<b>
															{{item.user_fullname}}
														</b> 
														<span class="">{{item.message}}</span>
													</p>
												</div>
											</div>
										</div>
									</CListGroupItem>
								</CListGroup>
								<CButton v-if="activity.showMore" @click="showMoreActivity" class="btn-link btn-sm w-100">
									<font-awesome-icon v-if="activity.loader" icon="spinner" spin class="mr-10"/>
									<font-awesome-icon v-else icon="chevron-down" class="mr-10"/>
									Lihat lebih banyak
								</CButton>
								<CButton v-if="!activity.showMore && activity.data.length > 5" @click="resetActivity" class="btn-link btn-sm w-100">
									<font-awesome-icon icon="chevron-up" class="mr-10"/>
									Muat lebih sedikit
								</CButton>
							</div>
						</CTab>
					</CTabs>
				</CCardBody>
			</CCard>
		</CCol>
	</CRow>

	<!-- Modal -->
	<CModal :show.sync="approval.popupModal.isShow">
		<template #header>
			<h5 class="modal-title">{{ approval.popupModal.modalTitle }}</h5>
		</template>
		<!-- Input Form -->
		<div class="p-1">
			<div class="mb-20">
				<label class="typo__label">Tindakan</label>
				<multiselect
					v-model="approval.formModel.input.action"
					:options="['Setuju','Tolak']"
					:allow-empty="false"
					:close-on-select="true"
					placeholder="Pilih Tindakan">
				</multiselect>
			</div>
			<div v-if="approval.formModel.input.action == 'Tolak'">
				<CTextarea
					v-model="approval.formModel.input.notes"
					label="Catatan"
					placeholder="Contoh: Surat Belum Sesuai."
					rows="3"/>
			</div>
		</div>
		<template #footer>
			<CButton @click="closeApprovalModal" class="btn-sm">Batal</CButton>
			<CButton @click="approvalAction" color="success" class="btn-sm">
				<font-awesome-icon v-if="approval.submitBtn.loader" icon="spinner" spin/> {{ approval.submitBtn.text }}
			</CButton>
		</template>
	</CModal>
</div>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import {
    mapState
} from 'vuex'

const avatar = require('@/assets/img/avatar.svg')
export default {
	names: 'Detail',
	data () {
		const approval = {
			data: {},
			popupModal: {
				isShow: false,
				modalTitle: 'Revisi Surat'
			},
			formModel: {
				input: {
					approvalId: null,
					action: null,
					notes: null
				}
			},
            submitBtn: {
				loader: false,
                text: 'Simpan'
            }
		}
		const document = {
			loader: false,
			data: {},
			user: {},
			approval: [],
			tags: [],
			marks: [],
			attachment: null,
			workflow: null,
			base64: ''
		}
		const comments = {
			loader: false,
			data: [],
			showMore: true,
			showMoreIndex: 0,
			form: {
				input: {
					doc_id: 0,
					message: ''
				},
				defaultValue: {
					doc_id: 0,
					message: ''
				},
				submitBtn: {
					loader: false
				}
			}
		}
		const activity = {
			loader: false,
			data: [],
			showMore: true,
			showMoreIndex: 0,
		}

		return {
			activeTab: 0,
			avatar,
			approval,
			document,
			comments,
			activity
		}
	},
	computed: {
		...mapState('auth', {
			fullname: 'fullname',
            profile_picture: 'profile_picture_thumb_base64'
		}),
		approvalId() {
			return this.$route.params.id
		}
	},
	watch: {
        workflowSelected(){
            this.validateApprovalForm('workflowSelected')
		},
        userTagged(){
            this.validateApprovalForm('userTagged')
		}
    },
	mounted () {
		this.approval.formModel.input.approvalId = this.approvalId
		this.loadContent()
	},
	methods: {
		async loadContent () {
            this.document.loader = true
			// Fetch approval data first, then fire required document apis
			let approvalModel = await this.getApproval()
				.catch((err) => {
                    this.document.loader = false
                    this.$toasted.error(err).goAway(3000)
                    return
				})
			this.approval.data = approvalModel
			this.comments.form.input.doc_id = approvalModel.document.doc_id
			this.comments.form.defaultValue.doc_id = approvalModel.document.doc_id
			// Load document components
			this.getDocumentData()
			this.getDocumentActivity()
			this.getDocumentComments()
			let docFileId = approvalModel.document.doc_file_id
			if (docFileId && docFileId != '')
				this.getPreviewItem()
			this.document.loader = false
		},
		getApproval () {
			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'approval/fetch',
						formInput: {
							approvalId: this.approvalId
                        }
					}
				).then( res => {
					if (res.message && res.message != '') {
						reject(res.message)	
					}
                    let item = res.result
					resolve(item)
				}).catch( err => {
                    reject(err)
                })
			})
		},
		getDocumentData () {
			this.document.loader = true
			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'document/getDocument',
						filter: {
							doc_id: this.approval.data.document.doc_id
						}
					}
				).then( res => {
					let data = res.result[0]
					this.document.data = {
						doc_id: data.doc_id,
						created_at: data.created_at,
						doc_file_id: data.doc_file_id,
						doc_no: data.doc_no,
						title: data.title,
						is_favorite: data.is_favorite,
					}
					this.document.user = data.user
					this.document.user.photo = (data.user.profile_picture_thumb_base64)?"data:image/jpeg;base64," + data.user.profile_picture_thumb_base64:''
					this.document.approval = data.approval
					this.document.tags = data.tags
					this.document.marks = data.marks
					this.document.attachment = data.attachment
					this.document.workflow = data.workflow
					console.log(this.document)
					let items = data
					resolve({ items })
				})
			})
		},
		getDocumentActivity () {
			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'document/getDocumentActivity',
						pagination: {
							limit: 5,
							offset: this.activity.showMoreIndex
						},
						filter: {
							doc_id: this.approval.data.document.doc_id
						}
					}
				).then( res => {
					let data = res.result
					data.forEach((item) => {
						let actRow = {
							created_at: item.created_at,
							message: item.message,
							user_fullname: item.user.fullname,
							user_photo: (item.user.profile_picture_thumb_base64)?"data:image/jpeg;base64," + item.user.profile_picture_thumb_base64:''
						}
						this.activity.data.push(actRow)
					})
					this.activity.showMoreIndex = this.activity.data.length
					let items = data
					resolve({ items })
				})
			})
		},
		showMoreActivity () {
			this.activity.loader = true
			this.getDocumentActivity()
			.then( data => {
				if (data.length > 0){
					data.forEach((item) => {
						let actRow = {
							created_at: item.created_at,
							message: item.message,
							user_fullname: item.user.fullname,
							user_photo: "data:image/jpeg;base64," + item.user.profile_picture_thumb_base64
						}
						this.activity.data.push(actRow)
					})
					this.activity.showMoreIndex = this.activity.data.length
				}else{
					this.activity.showMore = false
				}
				this.activity.loader = false
			})
		},
		resetActivity () {
			this.activity.data = []
			this.activity.showMore = true
			this.activity.showMoreIndex = 0
			this.getDocumentActivity()
			.then( data => {
				data.forEach((item) => {
					let actRow = {
						created_at: item.created_at,
						message: item.message,
						user_fullname: item.user.fullname,
						user_photo: "data:image/jpeg;base64," + item.user.profile_picture_thumb_base64
					}
					this.activity.data.push(actRow)
				})
			})
		},
		getDocumentComments () {
			return new Promise((resolve, reject) => {
				this.$store.dispatch(
					{
						type: 'document/getDocumentComments',
						pagination: {
							limit: 5,
							offset: this.comments.showMoreIndex
						},
						filter: {
							doc_id: this.approval.data.document.doc_id
						}
					}
				).then( res => {
					let data = res.result
					data.forEach((item) => {
						let actRow = {
							created_at: item.created_at,
							message: item.message,
							user_fullname: item.user.fullname,
							user_photo: (item.user.profile_picture_thumb_base64) ?
								"data:image/jpeg;base64," + item.user.profile_picture_thumb_base64 : ''
						}
						this.comments.data.push(actRow)
					})
					this.comments.showMoreIndex = this.comments.data.length
					let items = data
					resolve({ items })
				})
			})
		},
		showMoreComments () {
			this.comments.loader = true
			this.getDocumentComments()
			.then( data => {
				if (data.length > 0){
					data.forEach((item) => {
						let actRow = {
							created_at: item.created_at,
							message: item.message,
							user_fullname: item.user.fullname,
							user_photo: "data:image/jpeg;base64," + item.user.profile_picture_thumb_base64
						}
						this.comments.data.push(actRow)
					})
					this.comments.showMoreIndex = this.comments.data.length
				}else{
					this.comments.showMore = false
				}
				this.comments.loader = false
			})
		},
		resetComments () {
			this.comments.data = []
			this.comments.showMore = true
			this.comments.showMoreIndex = 0
			this.getDocumentComments()
			.then( data => {
				data.forEach((item) => {
					let actRow = {
						created_at: item.created_at,
						message: item.message,
						user_fullname: item.user.fullname,
						user_photo: "data:image/jpeg;base64," + item.user.profile_picture_thumb_base64
					}
					this.comments.data.push(actRow)
				})
			})
		},
		postComment () {
			this.comments.loader = true
			this.$store.dispatch({
				type: 'document/postComment',
				formInput: this.comments.form.input
			}).then( res => {
				let actRow = {
					created_at: res.created_at,
					message: this.comments.form.input.message,
					user_fullname: this.fullname,
					user_photo: this.profile_picture
				}
				this.comments.data.push(actRow)
				setTimeout( e => {
					this.comments.form.input = Object.assign({}, this.comments.form.defaultValue)
					this.comments.loader = false
				}, 1000)
			}).catch( err => {
				console.log(err)
				setTimeout( e => {
					this.comments.form.input = Object.assign({}, this.comments.form.defaultValue)
					this.comments.loader = false
				}, 1000)
			})
		},
		getPreviewItem () {
			this.$store.dispatch({
				type: 'document/getAttachmentBase64',
				formInput: {
					fileId: this.approval.data.document.doc_file_id,
					outputType: 'thumbnail'
				}
			}).then( res => {
				this.document.base64 = "data:image/jpeg;base64," + res.result.data.replace("b'","").replace("'","")
				this.document.loader = false
			})
		},
		getWorkflowList () {
			this.$store.dispatch(
                {
                    type: 'approval/getWorkflow',
                    filter: {
						approvalId: this.approval.data.document.doc_id
					}
                }
            ).then( res => {
                let items = res.receiver_list
                this.history.data = items
            }).catch( err => {
                this.history.loader = false
                this.$toasted.error('Gagal menambah data, silahkan coba kembali!').goAway(3000)
            })
		},
		// Revision Action
        showApprovalModal () {
			this.approval.popupModal.isShow = true
		},
		closeApprovalModal () {
			this.approval.popupModal.isShow = false
        },
        approvalAction () {
            this.approval.formModel.submitBtn = {
                loader: true,
                text: 'Memproses...'
            }

            // Set action approval
            this.$store.dispatch({
				type: 'approval/setActionApproval',
				formInput: this.approval.formModel.input
			}).then( res => {
				this.$toasted.success('Pengajuan berhasil direvisi').goAway(3000)
				this.closeApprovalForm()
			}).catch( err => {
                this.$toasted.error('Gagal merevisi surat, silahkan coba kembali!').goAway(3000)
                this.approval.formModel.submitBtn = {
                    loader: false,
                    text: 'Simpan'
                }
            })
        }
	}
}
</script>

<style scoped lang="scss">

</style>